








































































import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import WbTextField from '@/components/text_field/index.vue';
import submitOverrideCode from './dependencies/submit_override_code';
import archiveCreditCheck from './dependencies/archive_credit_check';
import getState from './dependencies/get_state';

export default Vue.extend({
  name: 'FrozenCreditProfile',

  components: {
    WbTextField,
  },

  data: () => ({
    loading: false,
    error: '',
    remainingArchiveAttempts: 0,
    form: {
      freezeOverrideCode: '',
    },
  }),

  validations: {
    form: {
      freezeOverrideCode: { required },
    },
  },

  computed: {
    overrideCodeErrors() {
      const errors: string[] = [];
      if (!this.$v?.form?.freezeOverrideCode?.$dirty) return errors;
      return errors;
    },
  },

  async created() {
    const { projectId, sessionId } = this.$route.params;
    const state = await getState(projectId, sessionId);
    this.remainingArchiveAttempts = state.remainingAttempts;
  },

  methods: {
    async submit() {
      this.error = '';
      this.$v.form.$touch();

      if (this.$v.form.$error) return;

      const { projectId, sessionId } = this.$route.params;
      try {
        this.loading = true;
        const { status } = await submitOverrideCode(projectId, sessionId, this.form);

        if (status === 'completed') {
          this.$router.replace({ path: 'qualify', query: this.$route.query });
        } else if (status === 'frozen') {
          this.error = 'Incorrect override code. Please try again';
        }
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.loading = false;
      }
    },

    async archive() {
      try {
        const { projectId, sessionId } = this.$route.params;
        await archiveCreditCheck(projectId, sessionId);
        this.$router.replace({ path: 'qualify', query: this.$route.query });
      // @ts-ignore
      } catch (error: Error) {
        if (error?.data?.message) {
          this.error = error.data.message;
        } else {
          this.error = "We've experienced an error.";
        }
      }
    },
  },
});
